<template>
  <div class="main-conatiner">
    <govstack-ribbon application-name="Citizen Portal" :token-claims="tokenClaims" :bearer-auth-token="idToken"></govstack-ribbon>
    <Header />
    <SubHeader />
    <main>
      <LoaderIcon v-if="loading" />
      <router-view v-else />
    </main>
    <Footer />
    <v-idle @idle="onidle" :loop="true" :wait="5" :duration="IDLETIMEOUT" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Header from './Header.vue'
import SubHeader from './SubHeader.vue'
import Footer from './Footer.vue'
import { IDLETIMEOUT } from '../../utilities/constants'
import LoaderIcon from '@/assets/svg/loader.svg'
import { useAxios } from '@/composables/useAxios'
import { ServiceUrls } from '@/utilities/serviceUrls'
export default {
  name: 'Layout',
  setup() {
    const { response: getUsersResponse, error: getUsersError, callApi: getUsersApi } = useAxios()
    return { getUsersResponse, getUsersError, getUsersApi }
  },
  data() {
    return {
      IDLETIMEOUT: IDLETIMEOUT,
      loading: true,
    }
  },
  components: {
    Header,
    SubHeader,
    Footer,
    LoaderIcon,
  },
  computed: {
    tokenClaims() {
      return this.userProfile ? JSON.stringify(this.userProfile) : ''
    },
    ...mapState({
      userRole: (state) => state.common.userRole,
      userProfile: (state) => state.common.userProfile,
      selectedTenant: (state) => state.common.selectedTenant,
      isEnabledUsers: (state) => state.common.isEnabledUsers,
      idToken: (state) => state.common.idToken
    }),
  },
  created() {
    this.getLoggedInDetails()
  },
  methods: {
    getLoggedInDetails() {
      if (this.userRole === 'SysAdmin' || this.userRole === 'Admin') {
        this.loading = true
        this.$store
          .dispatch('common/getAdminTenants')
          .then(async (res) => {
            await this.getUsers()
            if (this.$route.meta?.isMultilingualEnabled && this.userRole !== 'SysAdmin') {
              if (res.data?.length && !res.data[0].isMultilingualEnabled) {
                this.$router.push({ name: 'Widgest' }).catch(() => {})
              }
            }
            if (this.$route.meta?.isAnnouncementEnabled) {
              if (res.data?.length && !res.data[0].isAnnouncementEnabled) {
                this.$router.push({ name: 'Widgest' }).catch(() => {})
              }
            }
            if (this.$route.meta.requiresUserAuth && !this.isEnabledUsers) {
              await this.$router.push({ name: 'Cities' }).catch(() => {})
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        this.loading = false
      }
    },
    async getUsers() {
      const params = {
        tenantIds: this.userRole === 'SysAdmin' ? this.selectedTenant.map((tenant) => tenant.tenantID) : [],
        searchTxt: '',
        pageNumber: 1,
        pageSize: 10,
      }
      await this.getUsersApi({ method: 'post', url: ServiceUrls.getUsers, data: params })
      if (this.getUsersResponse) {
        this.$store.commit('common/setIsEnabledUsers', true)
      }
      if (this.getUsersError) {
        if (this.getUsersError.response.status === 401) {
          this.$store.commit('common/setIsEnabledUsers', false)
        } else {
          this.$store.commit('common/setIsEnabledUsers', true)
        }
      }
    },
    onidle() {
      this.$store.dispatch('common/signOut')
    },
  },
  watch: {
    userRole(newValue, oldValue) {
      this.getLoggedInDetails()
    },
  },
}
</script>
<style lang="scss" scoped></style>
